import React from 'react'
import MenuList from "../modules/Menu/pages/MenuList";

function MenuPage() {
    return (
        <div>
            <MenuList />
        </div>
    )
}

export default MenuPage
