import React from 'react'
import DishType from "../modules/Dishes/pages/DishType";
function DishTypePage() {
    return (
        <div>
            <DishType />
        </div>
    )
}

export default DishTypePage
