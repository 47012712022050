import React from 'react';
import DishNameList from "../modules/Dishes/pages/DishNameList"; 

function GenericDishName() {
    return (
        <div>
           <DishNameList /> 
        </div>
    )
}

export default GenericDishName
