import React from 'react'
import CuisineList from "../modules/Restaurant/pages/CuisineList";

function CuisinePage() {
    return (
        <div>
            <CuisineList />
        </div>
    )
}

export default CuisinePage
