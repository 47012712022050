import React from 'react'
import SpecialItems from "../modules/Dishes/pages/SpecialItems";
function SpecialItemPage() {
    return (
        <div>
            <SpecialItems />
        </div>
    )
}

export default SpecialItemPage;