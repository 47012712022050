import React from 'react'
import DishesList from "../modules/Dishes/pages/DishesList"

function DishesPage() {
    return (
        <div>
           <DishesList /> 
        </div>
    )
}

export default DishesPage
