import React from 'react'
import OrderList from "../modules/Orders/pages/OrderList";
function OrderPage() {
    return (
        <div>
          <OrderList />  
        </div>
    )
}

export default OrderPage
